body {
  font-family: 'Roboto', sans-serif;
}

.banner {
  position: relative;
  width: 100%;
  height: 600px; 
  background-image: url('../../../Assets/communication.jpg');
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  margin-top: 40px;
  margin-bottom: 22px;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 20px;
  box-sizing: border-box;
}

.member-title {
  color: white;
  font-size: 50px; 
  margin-bottom: 1px; 
  margin-top: 170px; 
  font-style: italic;
  font-family: fangsong;
  margin-bottom: 10px;
}

.buttons {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.btn {
  padding: 5px 15px;
  font-size: 14px;
  background-color: transparent;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  color: rgb(255, 255, 255);
  border: 2px solid white;
}

@media (min-width: 768px) {
  .title {
    font-size: 30px;
  }

  .btn {
    padding: 10px 20px;
    font-size: 17px;
  }
}

@media (min-width: 1024px) {
  .title {
    font-size: 40px;
  }

  .btn {
    padding: 10px 30px;
    font-size: 1.2em;
  }
}
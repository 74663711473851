header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 22px;
  margin: 10px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  background-color: #ffffff;
  color: #1c1818;
}

nav {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}

nav a {
  margin: 0 1rem;
  color: #000000;
  text-decoration: none;
  position: relative;
}
.demo {
  padding: 16px 27px;
  background: #3f3a80;
  color: white;
  border-radius: 34px;
  transition: transform 0.3s ease;

}


.demo:hover {
  transform: scale(0.9);
}

nav a:not(.demo)::before {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  bottom: -4px;
  left: 50%;
  background-color: #3f3a80;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
  transform: translateX(-50%);
}



nav a:hover::before {
  visibility: visible;
  width: 100%;
}

header .nav-btn {
  padding: 5px;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  color: #3f3a80;
  visibility: hidden;
  opacity: 0;
  font-size: 1.8rem;
}

header div,
nav {
  display: flex;
  align-items: center;
  justify-content: end;
}

header h3 {

  margin: 0;
  font-size: 33px;
  color: #3f3a80;
}

@media only screen and (max-width: 1024px) {
  header .nav-btn {
    visibility: visible;
    opacity: 1;
  }

  header nav {
    position: fixed;
    top: -100vh;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    background-color: white;
    transition: 1s;
    z-index: 9;
  }

  header .responsive_nav {
    transform: translateY(100vh);
  }

  nav .nav-close-btn {
    position: absolute;
    top: 2rem;
    right: 2rem;
  }

  nav a {
    font-size: 1.5rem;
  }
}



.css-1oqqzyl-MuiContainer-root {
  padding-left: 1px !important;
  padding-right: 1px !important;
}



@import url('https://fonts.googleapis.com/css2?family=Literata:ital,opsz,wght@0,7..72,200..900;1,7..72,200..900&family=Poetsen+One&display=swap');


.react-multiple-carousel__arrow {
  z-index: auto !important;
}


.react-slideshow-container .nav {
  z-index: auto !important;
}


@media only screen and (max-width: 660px) {
  .mediaImgCss {
    display: none;

  }

  .one {
    width: 80vw;
    padding: 30px !important;

  }

  .mediap {
    font-size: 13px !important;
    line-height: 15px !important;
  }

  .mediaTitle {
    font-size: 30px !important;
  }

  .mediaqimg {
    height: 200px !important;
  }

  .mediaqimg2 {
    height: 250px !important;
  }
}

.ggg {
border-radius: 30% 70% 70% 30% / 30% 30% 70% 70% ;
  height: 560px;
  width: 100%;

}

@media only screen and (max-width: 550px) {
  .ggg {
      height: 380px;
      width: 100%;    
    }
    .mediarevanue{
      padding: 10px !important;
    }
    
}


